<template>
  <Review
    title="Andrea Cappelletti"
    description="Reflusso Gastroesofageo"
    review="
    Sono un ragazzo di 21 anni, sin dall'adolescenza soffrivo di reflusso gastroesofageo.
Dopo aver trovato in rete un articolo (www.humanitas.it/news/13605-reflusso-gastro-esofageo-oggi-la-cura-e-endoscopica) che parlava di un'operazione svolta al S. Raffaele per il trattamento di questa patologia, scrissi un'email al Prof. Riccardo Rosati.

Precedentemente avevo fatto parecchie visite da vari gastroenterologi, ma il referto era sempre lo stesso: inibitore di pompa e gaviscon al bisogno per calmare il reflusso. 

A lungo andare i farmaci non mi facevano più effetto: si trattava di un reflusso ribelle e molto doloroso/ dannoso. Ero in cerca di una cura definitiva!

Effettuai Rx al tubo digerente, ph-metria delle 24h, gastroscopia, manometria esofagea stazionaria.

Dalla ph-metria delle 24h lo score di DeeMester risultava di 28, quando per una persona normale si aggira al massimo attorno a 14. E durante la giornata ebbi più di 80 episodi di reflusso con SAP (indice di correlazione al reflusso) = 100%.

Andai alla visita dal Prof. Rosati senza pretese (in quanto non sapevo se effettivamente esistesse questo intervento o meno). 
Trovai dall'altro lato della scrivania una persona molto professionale e competente.
Mi disse le cose come stavano: l'operazione alla mia età era vivamente consigliata.

Dopo aver discusso a lungo dei pro e dei contro ero sicuro di essere in buone mani.
Il segretario sig. Serra mi prenotò il pre-ricovero e il giorno dell'operazione (molto puntuale e competente).

L'operazione andò per il meglio.
Mi trovai davvero bene. 
Ero seguito da tutta l'equipe, in particolare dal Dott. Marcocci, il quale dimostrò alta professionalità, competenza e serietà e ebbe molta attenzione nei miei confronti anche dopo le dimissioni.

Rimasi ricoverato 3 giorni. La prima settimana fu molto dura per via dei tagli ma oggi (a distanza di due mesi) sono completamente soddisfatto della scelta di affidarmi al reparto.

La mia vita è cambiata. Ed è cambiata per il meglio.

Mi sembra un sogno riuscire a riposare indisturbato e mangiare un boccone senza aspettarmi che risalga quell'acido dannoso e fastidioso.

A circa un mese dall'intervento effettuai la Rx di controllo... risultato: tutto regolare.
Ma non avevo dubbi, ero sicuro di essere in buone mani e vivetti l'intero percorso nella serenità più assoluta.

La visita di controllo fu tenuta dal Dott. Puccetti, che si dimostrò professionale, competente e molto esaustivo.
E' un reparto di eccellenza, lo consiglio vivamente a tutti.
Non potevo chiedere di meglio.
Un grazie sentito a tutta l'equipe."
    :headerImage="localImage"
  />
</template>

<script>
import Review from "../../components/Review.vue";
import teamImage from "@/assets/member-background.jpg";

export default {
  name: "Andrea",
  components: {
    Review,
  },
  data() {
    return {
      localImage: teamImage,
    };
  },
};
</script>
